import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'coh-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Powered by <b><a href="https://www.coherentpixels.com/" target="_blank">Coherent</a></b> 2023</span>`,
})
export class FooterComponent {
  siteName: string;
  constructor() {
    this.siteName = environment.siteName;
  }
}
