import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../service';

@Injectable({
    providedIn: 'root'

})
export class AuthGaurd implements CanActivate{
    constructor(private authService:AuthService,private router:Router){

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.getUsers().pipe(
            take(1),
            map( user =>{
                const auth = user.jwtToken
                if(auth){
                    
                    return true;
                }
                return this.router.createUrlTree(['login'])
            }  
            )
        )
                 }
    
}