import { Component, OnInit } from '@angular/core';
import { MetaService } from './@core/service';

@Component({
  selector: 'coh-app',
  template: '<router-outlet></router-outlet>',
})

export class AppComponent implements OnInit {

  constructor(private metaService: MetaService) {
  }

  //this function is used to get meta data Ex: state,city...
  ngOnInit(): void {
    this.metaService.fetchMasterList();
  }

}
