import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { AuthService, HttpService, UtilService } from '../../../@core/service';
import { LayoutService } from '../../../@core/service';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { NbWindowService } from '@nebular/theme';
import { MessengerComponent } from '../../../pages/common/messenger/messenger.component';
import { NbSearchService } from '@nebular/theme';
const ROLEKEY='roleID';

@Component({
  selector: 'coh-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public userPictureOnly: boolean = false;
  public user: any;
  public siteName: string;
  public notifications: any;
  public subcription: Subscription;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    }
  ];

  currentTheme = 'default';
  value = '';
  roles = []
  roleName 
  role=false
  userRoles = [{ id: 1, title: 'TVR' }, { id: 2, title: 'CREDIT' }, { id: 5, title: 'OPS' }, { id: 3, title: 'CPV' }, { id: 4, title: 'FCU' }, { id: 6, title: 'Distributor' }]
  userMenu = [{ title: 'Profile', id: 'profile' }, { title: 'Log out', id: 'logout' }];
  roleValue: any;
  branch: string;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private windowService: NbWindowService,
    private searchService: NbSearchService,
    public utilservice: UtilService) {

    this.searchService.onSearchActivate()
      .subscribe((data: any) => {
        this.router.navigate(['/pages/tvrapplication/search']);
      })
  }




  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.siteName = environment.siteName;
    if(localStorage.getItem('title')){
      this.roleName= localStorage.getItem('title')
    }
else{
  this.roleName="TVR"
}

    this.authService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: any) => {
        this.user = user;
        this.branch=this.user['branch'] ? this.user['branch'].branchName : null
        console.log(this.user);
        if(this.user.hierarchyLevel==3){
          this.role=true
        }
        if (this.user.hierarchyLevel == 3) {
          localStorage[ROLEKEY] = 1
        }
      });

    this.setApprovers();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-tag'),
        map(({ item: menuItems }) => menuItems),
      )
      .subscribe(data => {
        this.menuItemEvent(data);
        let keys='title'
        if(data.title=='Log out'){
          localStorage.setItem(keys,'TVR')
        }
        else{
          localStorage.setItem(keys,data.title)
      this.roleName= localStorage.getItem('title')
        }      
      if(data['id']=='logout'){
        localStorage.clear()
        }
        else{          
          localStorage[ROLEKEY]=data['id']
          this.authService.setRole(data['id'])
        }
      });

    this.getNotification();

    this.subcription = this.authService._roleSubject.subscribe(
      (data) => {

        if (data) {
          // this.roleName = this.userRoles.find(x => x.id == data).title

          // console.log(data);
          // let key='roleID';
          // localStorage.setItem(key,data)
        

        }

      }
    )

  }

  private setApprovers() {

    if (this.user.approvers) {
      this.authService.getRole();

      for (var approver of this.user.approvers) {
        this.roles.push(
          {
            title: approver.approverTypeName,
            id: approver.approverLevel
          });

      }
      this.roles.sort(
        (a, b) => {
          return a.id - b.id;
        }
      )

      console.log("roles",this.roles)

    }

  }

  updatetogglebtn(data) {
    console.log('Hello world');
    // this.route.url.subscribe((val) => {
    //   console.log(val)
    // })
    var status;
    this.utilservice.showcheckbox.subscribe(res=>{
      status = res.status
    })
    // console.log(status);
    this.utilservice.update_branchcheckbox({toggle:data.checked,status:status});
  }

  notificationOnClick(data) {
    console.log(data);
    this.httpService.callGetAPI('lead-service/application/updateNotification?id=' + data).subscribe(data => {
      if (data.statusCode === '200' && data.statusMessage === 'Success')
        this.notifications = data.data;
      this.getNotification();
    })
  }

  getNotification() {
    this.httpService.callGetAPI('lead-service/application/getNoticationList').subscribe(data => {
      if (data.statusCode === '200' && data.statusMessage === 'Success')
        this.notifications = data.data;
    })
  }




  menuItemEvent(menuItem) {
    if (menuItem.id === 'profile') {
      this.router.navigate(['pages/profile']);
    } else if (menuItem.id === 'logout') {
      if (this.roleName) {
        this.authService.updateRoleSubject(0)
      }
      this.utilservice.update_customercheckbox({toggle:false,status:'customer'});
      this.authService.logout();
    } else if (menuItem.id !== 'profile' && menuItem.id !== 'logout') {
      this.authService.updateRoleSubject(+menuItem.id)
      this.router.navigate(['pages/home'])
      // console.log("menuItem",menuItem.id)
      // this.router.navigateByUrl('pages/'+menuItem.title+'/application')
    }

  }

  async reload(url: string, id): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    this.authService.updateRoleSubject(+id)

    return this.router.navigateByUrl(url);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subcription.unsubscribe()

  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }


  toggleTheme() {
    if (this.currentTheme === 'default') {
      this.currentTheme = 'dark';
      console.log('dark');
      this.utilservice.changeInputtel(true);
    } else {
      this.currentTheme = 'default';
      console.log('default');
      this.utilservice.changeInputtel(false);
    }
    this.themeService.changeTheme(this.currentTheme);

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  getUserName(user) {
    return user ? `${this.capitalize(user.firstName)} ${this.capitalize(user.lastName)}` : 'Anonymous';
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  openMessenger() {
    console.log('open messenger');
    this.windowService.open(
      MessengerComponent,
      { title: 'Messenger' },
    );
  }
}
