import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'coh-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent {

  messages: any[];

  constructor() {
    this.messages = [
  {
    text: 'Hello, how are you? This should be a very long message so that we can test how it fit into the screen.',
    reply: false,
    date: new Date(),
    user: {
      name: 'John Doe',
      avatar: 'https://i.gifer.com/no.gif',
    },
  }
];
  }

  sendMessage(event: any) {
    
    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: true,
      type: 'text',
      user: {
        name: 'Raghul Selvam'
      },
    });
  }

}
