import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxRupee' })
export class RupeePipe implements PipeTransform {
 
  // transform(value: number): string {
  //   if (isNaN(value)) {
  //     return value.toString();
  //   }
  //   const [integer, fractional] = value.toFixed(2).split('.');
  //   const integerPart = parseInt(integer).toLocaleString('en-IN', {maximumFractionDigits: 0, minimumFractionDigits: 0});
  //   const formattedValue = '₹' + integerPart + '.' + fractional;
  //   return formattedValue;
  // }
  transform(value: number): string {
    if (isNaN(value)) {
      return value.toString();
    }
    const integerPart = value.toLocaleString('en-IN', {maximumFractionDigits: 2,  style: 'currency',
    currency: 'INR'});
    return integerPart;
  }
}
