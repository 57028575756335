import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'employeeTitle' })
export class EmployeeTitlePipe  implements PipeTransform {

    transform(value: any, gender: any): string {  
        
        if (gender == "male") {
             return "Mr." + value;
            } 
  
        else if (gender == "female") { 
            return "Miss/Mrs." + value;  
        }

        else {
            return "" + value;  
        }
    }  
}
