import { Injectable } from '@angular/core';
import { NbToastrService, NbGlobalPosition } from '@nebular/theme';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public application;
  public lead;
  
  public showcheckbox = new BehaviorSubject({toggle:false,status:'default'});
  public showcheckbox2 = new BehaviorSubject({toggle:false,status:'default'});

  public inputtel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public inputtelObs = this.inputtel.asObservable();

  public approvals={
   
    history:{
      approve:false,
      approveStatus:[
        {
          id:'TVR',
          status:'Pending',
        },
        {
          id:'credit',
          status:'Approved'
        },
        {
          id:'Ops',
          status:"Rejected"
        }
      ],
      

    },
    1:{
      name:'tvr',
      approve:true,
      approveStatus:[
        {
          id:'cse',
          status:'pending'
        }
      ],
      buttonActions:[
        {id: 8, action: "recommend",button:"primary"}, 
        {id: 4, action: "Not recommend",button:"danger"},
      
      ]

    },
    2:{

      name:'credit',
      approve:true,
      verificationActions:[
        {id: 5, action: "Move To CPV",button:"primary"},
        {id:6 ,action:"Move To FCU",button:"primary"},
      ],
      
      buttonActions:[
        
        {id: 1, action: "Approve",button:"success"}, 
        {id: 2, action: "Reject",button:"danger"},
       {id:3 ,action:"Hold",button:"warning"}
      ]
    },
    
    3:{

      name:'CPV',
      approve:true,
      
      buttonActions:[
        {id: 8, action: "recommend",button:"primary"}, 
        {id: 4, action: "Not recommend",button:"danger"},
      
      ]
    },
    4:{

      name:'FCU',
      approve:true,
      
      buttonActions:[
        {id: 8, action: "recommend",button:"primary"}, 
        {id: 4, action: "Not recommend",button:"danger"},
      
      ]
    },
    5:{

      name:'ops',
      approve:true,
      approveStatus:[
        {
          id:'cse',
          status:'pending'
        },
        {
          id:'tvr',
          status:'pending'
        },
        {
          id:'credit',
          status:'pending'
        }
      ],
      verificationActions:[
        {id: 5, action: "Move To CPV",button:"primary"},
        {id:6 ,action:"Move To FCU",button:"primary"},
        {id:13 ,action:"Move To Credit",button:"primary"},

      ],
      buttonActions:[
        
        {id: 1, action: "Approve",button:"success"}, 
        {id: 2, action: "Reject",button:"danger"},
         {id:3 ,action:"Hold",button:"warning"}
      ]
    },
    6:{

      name:'distributor',
      approve:true,
      approveStatus:[
        {
          id:'cse',
          status:'pending'
        },
        {
          id:'tvr',
          status:'pending'
        },
        {
          id:'credit',
          status:'pending'
        }
      ],
      buttonActions:[
        {id: 11, action: "Disburse",button:"primary"}, 
        // {id: 2, action: "Rejected",button:"danger"},
        // {id:3 ,action:"Hold",button:"warning"}
      ]
    },

    
    'reacquire':{

      name:'reacquire',
      approve:true,
      // approveStatus:[
      //   {
      //     id:'cse',
      //     status:'pending'
      //   },
      //   {
      //     id:'tvr',
      //     status:'pending'
      //   },
      //   {
      //     id:'credit',
      //     status:'pending'
      //   }
      // ],
      buttonActions:[
        {id: 11, action: "submit",button:"primary"}, 
        // {id: 2, action: "Rejected",button:"danger"},
        // {id:3 ,action:"Hold",button:"warning"}
      ]
    },
    


  }
  constructor(
    private toastrService: NbToastrService
    ) {
   }

showToast(message: string, status) {
  this.toastrService.show(
    // status || 'Success',
    '',
    message,
    { status });
}
setApplication(app){
  this.application=app;
}
getApplication(){
  return this.application;
}

setLead(data){
  this.lead=data;
}
getLead(){
  return this.lead;
}

changeInputtel(data) {
  console.log(data);
  this.inputtel.next(data);
}

update_branchcheckbox(data){
  console.log('Util service');
  console.log(data);
  this.showcheckbox.next(data);
}

update_customercheckbox(data){
  console.log('Util service');
  console.log(data);
  this.showcheckbox2.next(data);
}

}


