import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MetaService {
  masterList = [];
  public _masterList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  constructor(private httpService: HttpService) {}

  fetchMasterList() {
    this.httpService
      .callGetAPI("master-service/master/meta")
      .subscribe((data) => {
        if (data.statusCode === "200" && data.statusMessage === "Success")
          this.masterList = data.data;
          this._masterList.next(data.data)
      });
  }

  fetchMasterListData(e) {
    this.httpService
      .callGetAPI("master-service/master/meta")
      .subscribe((data) => {
        if (data.statusCode === "200" && data.statusMessage === "Success")
          this.masterList = data.data;
        return this.masterList[e];
      });
  }

  getMasterListOf(masterName) {
    return this.masterList[masterName];
  }
  get masterList$(): Observable<any[]> {
    return this._masterList.asObservable();
  }
}
