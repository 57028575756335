import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './@core/service';

@Injectable({
    providedIn: 'root'
})
export class AppInterceptor implements HttpInterceptor {
    constructor(public authService: AuthService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        const token = this.authService.decryption(localStorage.getItem(environment.localStorageName))['jwtToken'];

        if (localStorage.getItem(environment.localStorageName) != null && (!request.url.endsWith('userLogin') && !request.url.endsWith('generateOTP') && !request.url.endsWith('getUserStatusById') && !request.url.startsWith('https://ifsc-lookup-api.p.rapidapi.com') )) {
            console.log(request.url)
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if ((!request.headers.has('Content-Type')) && !(request.url.split('?')[0].endsWith('documentUpload')) && !(request.url.split('?')[0].endsWith('readExcel')) ) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        // if (localStorage.getItem(environment.localStorageName) != null && (request.url.split('?')[0].endsWith('documentUpload'))) {
        //   console.log("inside the lead-service/documentUpload");
        //   request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data') });
        //   request = request.clone({ headers: request.headers.set('Accept', '*/*') });
        // }

        if (localStorage.getItem(environment.localStorageName) != null && ((request.url.endsWith('collection/download')) || (request.url.endsWith('collectable/download')) || (request.url.endsWith('summary/download')) || (request.url.endsWith('achReceipts/download')) || (request.url.endsWith('dashboard/download')))) {

            request = request.clone({ headers: request.headers.set('Accept', 'text/csv; ') });
            //request = request.clone({ headers: request.headers.set('Content-Type', 'text/csv;') });

        }

        // if (localStorage.getItem(environment.localStorageName) != null && (request.url.endsWith('appId=86'))) {
        //   console.log("inside the lead-service/documentUpload");
        //   request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data') });
        //   request = request.clone({ headers: request.headers.set('Accept', '*/*') });
        // }


        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                // this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
    }
}
