import { LayoutService } from './layout.service';
import { UtilService } from './util.service';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { HomeService } from './home.service';
import { WebSocketServiceService } from './WebSocketService.service';
import { MetaService } from './meta.service';

export const services = [
  LayoutService,
  AuthService,
  HttpService,
  UtilService,
  HomeService,
  MetaService
];

export {
  LayoutService,
  AuthService,
  HttpService,
  UtilService,
  HomeService,
  MetaService
};
